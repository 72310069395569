<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <!-- Start Page -->
  <div id="page" class="">
    <!-- Page Content -->
    <div class="page-content pb-0">
      <div class="card rounded-0 mb-0" data-card-height="cover-full">
        <div class="card-center ps-3">
          <h1 class="text-center mb-4 font-40 font-800">
            Ticket<span
              class="
                gradient-highlight
                p-2
                mx-1
                color-white
                scale-box
                d-inline-block
                rounded-s
                border-0
              "
            ></span>
          </h1>

          <form @submit.prevent="submitForm">
            <div class="ms-3 me-4 mb-n3">
              <div class="input-style no-borders no-icon validate-field mb-4">
                <input
                  type="email"
                  class="form-control validate-name"
                  id="form-email"
                  placeholder="Email"
                  v-model="email"
                />
                <label for="form-email" class="color-highlight">Username</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>
              <div class="input-style no-borders no-icon validate-field mb-4">
                <input
                  type="password"
                  class="form-control validate-password"
                  id="form-password"
                  placeholder="Password"
                  v-model="password"
                />
                <label for="form-password" class="color-highlight"
                  >Password</label
                >
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>
              <div class="clearfix"></div>
              <div
                class="form-check icon-check text-start float-start opacity-50"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="check4"
                />
                <label class="form-check-label font-13" for="check4"
                  >Remember Me</label
                >
                <i
                  class="icon-check-1 far fa-circle color-gray-dark font-14"
                ></i>
                <i
                  class="
                    icon-check-2
                    fa fa-check-circle
                    font-14
                    color-highlight
                  "
                ></i>
              </div>

              <a href="#" class="font-11 float-end color-theme opacity-30"
                >Forgot Password?</a
              >
              <div class="clearfix"></div>
            </div>
            <button
              type="submit"
              class="
                btn btn-center-l
                gradient-highlight
                rounded-sm
                btn-l
                font-13 font-600
                mt-5
                border-0
              "
            >
              Sign In
            </button>
          </form>

          <span class="text-center d-block font-600 opacity-30 mt-3 mb-3"
            >or</span
          >
          <div class="text-center">
            <a
              @click="googleSignIn"
              class="
                btn btn-m btn-m
                mb-3
                rounded-xs
                text-uppercase
                font-700
                shadow-s
                me-2
              "
              style="background: rgb(179, 201, 232) none repeat scroll 0% 0%"
              ><i class="fab fa-google me-2"></i> Google</a
            >
            <a
              href="#"
              class="
                btn btn-m btn-m
                mb-3
                rounded-xs
                text-uppercase
                font-700
                shadow-s
                bg-black
              "
              ><i class="fab fa-apple me-2"></i>Apple</a
            >
          </div>

          <div class="text-center">
            <p class="boxed-text-l mb-4">
              Don't have an Account ?
              <router-link :to="{ name: 'SignUp' }">Register Now</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Content-->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/services/api";
import { init_template, menuClose } from "@/appkit.js";

import Footer from "@/components/Footer.vue";

export default {
  name: "SignIn",
  components: {
    Footer,
  },
  data() {
    return {
      email: "",
      password: "",
      errors: [],
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuClose,
    submitForm() {
      const formData = {
        email: this.email,
        password: this.password,
      };

      this.$store
        .dispatch("auth/signIn", { formData })
        .then((success) => {
          const toPath = this.$route.query.to || { name: "TicketHome" };
          this.$router.push(toPath);
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    },
    async googleSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        console.log('gogole user : ', googleUser)
        if (!googleUser) {
          return null;
        }
        this.user = googleUser.getBasicProfile().getEmail();
        if (googleUser.getAuthResponse) {
          const formData = {
            access_token: googleUser.getAuthResponse().access_token,
            id_token: googleUser.getAuthResponse().id_token,
            social: "google",
          };
          this.$store
            .dispatch("auth/signInOauth", { formData })
            .then((success) => {
              const toPath = this.$route.query.to || { name: "TicketHome" };
              this.$router.push(toPath);
            })
            .catch((error) => {
              console.log("error : ", error);
            });
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
};
</script>
